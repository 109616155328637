@font-face {
  font-family: CustomFontName;
  src: url(../../assets/fonts/usedFont.otf);
}
.EIDmubark {
  min-width: fit-content;
  max-height: fit-content;
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(../../assets/images/bg3.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.EIDmubarkBox {
  background: url(../../assets/images/eidMubark2.jpeg);
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  /* width: 345px;
  height: 616px; */
  height: 400px;
    /* max-width: 100%; */
    width: 561px;
  /* max-width: 100%; */
  background-size: 100% auto;
}

.form {
  text-align: center !important;
}

.userNameEid {
  color: white !important;
  position: absolute;
  bottom: 13%;
  font-size: smaller;
  width: 100%;
  left: 27%;
}
.userNameEid h5 {
  font-size: 12px !important;
  font-family: CustomFontName;
}

.label {
  font-size: 20px;
  font-weight: 400;
  color: white;
}

.save {
  width: 125px;
  font-size: 20px;
  margin: 20px;
  background-color: #1c9ca9 !important;
  color: white;
}
.save :hover {
  background-color: "#1c9ca9";
}

.MuiButton-label {
  color: white;
}
.MuiButton-contained {
  margin-top: 15px !important;
}
.input {
  display: block;
  max-width: 100%;
  margin: auto;
  border-radius: 10px;
  resize: none;
  outline: none;
  box-sizing: border-box;
  padding: 10px;
  font-size: 15px;
  font-weight: 400;
}

@media only screen and (max-width: 600px) and (min-width: 400px) {
  .EIDmubarkBox {
    background: url(../../assets/images/eidMubark2.jpeg);
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    width: 277px;
    height: 200px;
    background-size: 100% auto;
  }
  .userNameEid {
    color: white !important;
    position: absolute;
    bottom: -2%;
    width: 100%;
  }
  .userNameEid h5 {
    font-size: 5px !important;
    font-family: CustomFontName;
    margin-bottom: 33px;
  }
}
@media only screen and (max-width: 400px) and (min-width: 320px) {
  .EIDmubarkBox {
    background: url(../../assets/images/eidMubark2.jpeg);
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    width: 258px;
    height: 200px;
    background-size: 100% auto;
  }
  .userNameEid {
    color: white !important;
    position: absolute;
    bottom: 6%;
    width: 100%;
  }
  .userNameEid h5 {
    font-size: 5px !important;
    font-family: CustomFontName;
    margin-bottom: 30px;
  }
}
