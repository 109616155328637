* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* backface-visibility: hidden; */
  /* transform: translateZ(0); */
}

html {
  background-color: #f7f8fb;
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}

@font-face {
  font-family: "BahijJanna";
  src: local("BahijJanna"), url(./fonts/BahijJanna.ttf) format("truetype");
}

/* @font-face {
  font-family: "Cairo";
  src: local("Cairo"), url(./fonts/Cairo/Cairo-Regular.ttf) format("truetype");
} */

#root {
  font-family: "BahijJanna";
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  background-color: #f7f8fb;
  width: 100%;
  min-height: 90vh;
  margin-bottom: 50px;
}

.react-tel-input .form-control {
  max-width: 500px !important;
  width: auto;
  height: 50px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media only screen and (min-width: 1280px) {
  .react-tel-input {
    top: 10px;
  }
}

.Toastify__toast--default {
  background: #fff;
  color: #aaa;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
}
.Toastify__toast--info {
  background: #38c7d5;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
}
.Toastify__toast--success {
  background: #449d48;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
}
.Toastify__toast--warning {
  background: #cca300;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
}
.Toastify__toast--error {
  background: #f33223;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
}

/* scroll bar */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.react-tel-input .form-control {
  max-width: 533px !important;
  width: 100%;
  height: 50px;
}

.error.react-tel-input .form-control {
  border: 1px solid red;
}
.root.react-tel-input .form-control {
  border: 1px solid #cacaca;
}
.error-msg{
  color: red;
margin-top: 7px;
font-size: 0.75rem;
text-align: left;
margin-left: 14px;
margin-right: 14px;
}

.react-tel-input .country-list{
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 400px;
}

