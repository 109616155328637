.lang {
  margin: 5;
  color: white;
  background-color: #333542;
  border: "none";
  cursor: "pointer";
  width: 30px;
  height: 25px;
  border-radius: 4px;
  background-color: #2196f3;
}

.header-logo img {
  max-width: 190px;
  margin-left: 1rem;
}

.cover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(../../assets/images/Cover.png);
  background-size: cover;
}

@media only screen and (max-width: 989px) {
  h1 {
    font-size: 4rem;
  }

  p.lead {
    font-size: 1.2rem !important;
    line-height: 1.3;
    color: #fafafa;
    margin-bottom: 3.6rem;
  }
}
@media only screen and (max-width: 768px) {
  h1 {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 2.6rem;
  }
}

p.lead {
  font-size: 1.3rem;
  line-height: 1.3;
  color: #fafafa;
  margin-bottom: 2.6rem;
}

@media only screen and (max-width: 768px) {
  p.lead {
    font-size: 1.6rem;
  }
}

.button {
  display: inline-block;
  font-size: 1.1rem;
  height: 3.4rem;
  padding: 0 2rem;
  margin: 0 0.3rem 1.2rem 0;
  color: #706767;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  border-radius: 0.5rem;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #1c9ca9;
  border: 0.1rem solid #1c9ca9;
}

.btn-jittery {
  animation: jittery 2s infinite;
}

.btn-jittery:hover {
  animation: jelly 1s infinite;
}

@keyframes jittery {
  5%,
  50% {
    transform: scale(1);
  }

  10% {
    transform: scale(0.9);
  }

  15% {
    transform: scale(1.15);
  }

  20% {
    transform: scale(1.15) rotate(-5deg);
  }

  25% {
    transform: scale(1.15) rotate(5deg);
  }

  30% {
    transform: scale(1.15) rotate(-3deg);
  }

  35% {
    transform: scale(1.15) rotate(2deg);
  }

  40% {
    transform: scale(1.15) rotate(0);
  }
}

@keyframes jelly {
  25% {
    transform: scale(0.9, 1.1);
  }

  50% {
    transform: scale(1.1, 0.9);
  }

  75% {
    transform: scale(0.95, 1.05);
  }
}

button.stroke,
.button.stroke {
  background: transparent !important;
  color: #1c9ca9 !important;
}

#header {
  width: 100%;
  height: 72px;
  position: absolute;
  top: 0%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 501;
  display: flex;
  flex-direction: row-reverse;
}

.header-logo {
  position: absolute;
  left: 40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  z-index: 501;
}

#home {
  width: 100%;
  height: 100vh;
  background-color: transparent;
  position: relative;
  display: table;
}

.home-content {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: bottom;
}

.home-content-left {
  padding: 6rem 8rem 100px;
  position: relative;
  width: 50%;
}

.home-content-left h1 {
  font-size: 2.8rem;
  color: #ffffff;
  margin-bottom: 2rem;
  line-height: 1;
}

.home-content-left .button.stroke {
  color: #1c9ca9 !important;
  border-color: #1c9ca9;
  margin: 0 0.3rem 0.7rem;
  padding: 0 1.1rem;
  /* min-width: 188px; */
  padding: 0 2rem;
}

.home-content-left .button.strokesolid {
  background-color: #1c9ca9 !important;
  color: #faf6f6 !important;
}

.home-content-left .button.stroke:hover {
  background-color: #1c9ca9 !important;
  border-color: #1c9ca9;
  color: #faf6f6 !important;
}

.social-media-icons ul > li {
  margin-left: 0.5rem;
  margin-right: 2rem;
  display: inline-block;
}

.social-media-icons ul > li > a {
  display: block;
}

.home-social-list {
  position: absolute;
  right: 5%;
  bottom: -2%;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 1.6rem;
  line-height: 1.75;
  text-align: center;
  -webkit-transform: translateY(-55%);
  -ms-transform: translateY(-55%);
  transform: translateY(-55%);
}

.home-social-list li a {
  color: #1c9ca9;
  margin-right: -1.5rem;
}

.home-social-list-fa a:hover {
  color: #1a61be !important;
}

.home-social-list-in a:hover {
  color: #c32aa3 !important;
}
.home-social-list-tw a:hover {
  color: #33a7f0 !important;
}
.home-social-list-wh a:hover {
  color: #25d366 !important;
}

.home-social-list-sn a:hover {
  color: #f5f118 !important;
}

.home-social-list-lin a:hover {
  color: #0e4fb1 !important;
}

@media only screen and (max-width: 1300px) {
  #home {
    overflow: hidden;
  }

  .home-content-left {
    padding: 6rem 4rem 6rem 40px;
  }

  .home-content-left h1 {
    font-size: 3.4rem;
  }
}

@media only screen and (max-width: 1100px) {
  .home-content-left h1 {
    font-size: 2.6rem;
  }
}

@media only screen and (max-width: 1024px) {
  .home-content-left {
    width: 60%;
    padding: 6rem 3rem 6rem 2rem;
  }
  .home-content-left h1 {
    font-size: 2.2rem;
  }
}

@media only screen and (max-width: 900px) {
  #home,
  .home-content {
    display: block;
    text-align: center;
  }
  .home-content-left {
    width: 94%;
    padding: 8rem 6rem 366px;
    margin: 0 auto;
  }
  .home-content-left h1 {
    margin-bottom: 0.8rem;
  }
}

@media only screen and (max-width: 600px) {
  .home-content-left h1 {
    font-size: 1.3rem !important;
  }
}

@media only screen and (max-width: 699px) {
  .home-content-left {
    padding: 6rem 2.5rem 0px;
  }
  .home-content-left h1 {
    font-size: 1.5rem !important;
  }
  .home-content-left .lead {
    font-size: 1rem !important;
  }
  .home-content-left .button.stroke {
    min-width: 50px;
    font-size: 0.75rem;
  }
}

@media only screen and (max-width: 320px) {
  .home-social-list {
    position: absolute;
    right: 5%;
    bottom: -6%;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 1.6rem;
    line-height: 1.75;
    text-align: center;
    -webkit-transform: translateY(-55%);
    -ms-transform: translateY(-55%);
    transform: translateY(-55%);
  }
}
