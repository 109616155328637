@font-face {
  font-family: CustomFontName;
  src: url(../../assets/fonts/ramadanFont.otf);
}
.eidAdha {
  min-width: fit-content;
  max-height: fit-content;
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(../../assets/images/bg3.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.Aladha {
  background: url(../../assets/images/adha.jpeg) ;
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  width: 550px;
  height: 550px;
  /* max-width: 100%; */
  background-size: 100% auto;
}

.form {
  text-align: center !important;
}

.userNamee {
  color: #424040 !important;
  position: absolute;
  bottom: 11% !important;
  font-size: 33px;
  width: 100%;
}
.userNamee h3 {
  font-size: 33px !important;
  font-family: CustomFontName;
}

.label {
  font-size: 20px;
  font-weight: 400;
  color: white;
}

.save {
  width: 125px;
  font-size: 20px;
  margin: 20px;
  background-color: #1c9ca9 !important;
  color: white;
}
.save :hover {
  background-color: "#1c9ca9";
}

.MuiButton-label {
  color: white;
}
.MuiButton-contained {
  margin-top: 15px !important;
}
.input {
  display: block;
  max-width: 100%;
  margin: auto;
  border-radius: 10px;
  resize: none;
  outline: none;
  box-sizing: border-box;
  padding: 10px;
  font-size: 15px;
  font-weight: 400;
}

@media only screen and (max-width: 600px) and (min-width: 400px) {
  .Aladha {
    background: url(../../assets/images/adha.jpeg);
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    width: 400px;
    height: 400px;
    background-size: 100% auto;
  }
  .userNamee {
    color: white;
    position: absolute;
    bottom: 10% !important;
    width: 100%;
  }
  .userNamee h3 {
    font-size: 28px !important;
  }
}
@media only screen and (max-width: 400px) and (min-width: 320px) {
  .Aladha {
    background: url(../../assets/images/adha.jpeg) ;
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    width: 335px;
    height: 335px;
    background-size: 100% auto;
  }
  .userNamee {
    color: white;
    position: absolute;
    bottom: 11% !important;
    width: 100%;
  }
  .userNamee h3 {
    font-size: 20px !important;
  }
}

